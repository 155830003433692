<template>
  <div class="echarts-main">
    <div class="chart-title-box">
      <p class="chart-title">
        金额趋势 <span>(单位：元)</span>
      </p>
      <el-radio-group
        v-model="configGroup.tabPosition"
        class="title-radio-group"
        @change="echartsOperate.getData"
      >
        <el-radio-button
          v-for="item in configGroup.tabList"
          :key="item"
          :label="item"
        >
          {{ item === "02" ? "本年" : "本月" }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div ref="chartRef" class="echart-box" />
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, nextTick } from 'vue'
import { getBuyAmountChart, getIncomeAmountChart } from '@/api/piaoju'
import { numberToCurrency } from '@/utils/util.js'
import * as echarts from 'echarts'

const chartRef = ref(null)
const configGroup = reactive({
  tabList: ['01', '02'],
  tabPosition: '01'
})
const echartsData = reactive({
  chartOption: {
    color: ['#0172EB'],
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        let str =
          `<span style="font-size: 12px">${params[0].axisValue}</span>` +
          '<br />'
        params.forEach((item) => {
          str +=
            `<span>${item.seriesName}</span>` +
            ' : ' +
            `<span style="margin-left: 10px;">${numberToCurrency(item.data)}</span>` +
            '<br />'
        })
        return str
      }
    },
    grid: {
      top: '20%',
      left: '10%',
      right: '10%',
      bottom: '16%'
    },
    legend: {
      x: 'center',
      bottom: '0',
      itemWidth: 6, // 设置宽度
      itemHeight: 6, // 设置高度
      itemGap: 20, // 间距
      icon: 'circle',
      textStyle: {
        fontSize: '14'
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      axisLine: {
        // 坐标轴轴线相关设置。数学上的x轴
        show: true,
        lineStyle: {
          color: '#E7E7E7'
        }
      },
      axisLabel: {
        // 坐标轴刻度标签的相关设置
        color: '#333',
        fontSize: 14
      }
    },
    yAxis: [
      {
        name: '购买金额',
        type: 'value',
        // nameGap: '40',
        nameTextStyle: {
          color: '#333',
          fontSize: 14,
          padding: [0, 0, 20, -72]
        },
        axisLabel: {
          show: true,
          color: '#333',
          fontSize: 14
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E7E7E7',
            type: 'dashed'
          }
        }
      },
      {
        name: '发放收益',
        type: 'value',
        nameTextStyle: {
          color: '#333',
          fontSize: 14,
          padding: [0, -72, 20, 0]
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: true,
          color: '#333',
          fontSize: 14
        }
      }
    ],
    series: [
      {
        name: '购买金额',
        data: [],
        type: 'bar',
        barWidth: '16px',
        yAxisIndex: 0,
        itemStyle: {
          color: '#0172EB',
          borderRadius: [10, 10, 0, 0]
        }
      },
      {
        name: '发放收益',
        data: [],
        type: 'line',
        yAxisIndex: 1,
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: 'rgba(9, 175, 66, 1)'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(9, 175, 66, 0.4)'
            },
            {
              offset: 1,
              color: 'rgba(255,255,255, 0.1)'
            }
          ])
        }
      }

    ]
  }
})

const echartsOperate = reactive({
  rendChartsOne() {
    const myCharts = echarts.init(chartRef.value)
    myCharts.setOption(echartsData.chartOption, true)
    window.addEventListener('resize', function() {
      if (myCharts) myCharts.resize()
    })
  },
  getData() {
    Promise.all([
      getBuyAmountChart(configGroup.tabPosition),
      getIncomeAmountChart(configGroup.tabPosition)
    ])
      .then(([buyMount, incomeAmount]) => {
        const buyMountArr = buyMount.data
        const incomeAmountArr = incomeAmount.data
        const xData = buyMountArr.map((item) => item.xdate)
        const yData = buyMountArr.map((item) => item.yamount)
        echartsData.chartOption.xAxis.data = xData
        echartsData.chartOption.series[0].data = yData
        const yDataIncome = incomeAmountArr.map((item) => item.yamount)
        echartsData.chartOption.series[1].data = yDataIncome
        nextTick(() => {
          echartsOperate.rendChartsOne()
        })
      })
  }
})
onMounted(() => {
  echartsOperate.getData()
})
</script>

<style lang="scss" scoped>
.echarts-main {
  width: 100%;
  height: 508px;
  padding: 24px;
  box-shadow: -4px -4px 10px 0 #eaedf566, 4px 4px 10px 0 #eaedf566;
  box-sizing: border-box;

  .chart-title-box {
    display: flex;
    justify-content: space-between;

    :deep(.el-radio-button__original-radio:checked+.el-radio-button__inner) {
      background-color: '#0172EB';
    }
  }

  .chart-title{
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    span {
      font-size: 14px;
      color: #666;
    }
  }

  .echart-box {
    width: 100%;
    height: 430px;
  }

  /deep/ .el-radio-button__inner {
    padding: 8px 16px;
  }

}

</style>
