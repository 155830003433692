<template>
  <!-- 首页 -->
  <div class="home-page page-info-content ">
    <div class="home-left">
      <HomeTop />
      <HomeBottom />
    </div>
    <div class="home-right">
      <HomeRight />
    </div>
  </div>
</template>

<script>
import HomeTop from './components/homeTop.vue'
import HomeBottom from './components/homeBottom.vue'
import HomeRight from './components/homeRight.vue'
export default {
  components: { HomeTop, HomeBottom, HomeRight }
}

</script>

<style lang="scss" scoped>
.home-page {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.home-left {
  width: calc(100% - 368px);
  margin-right: 24px;
}
.home-right {
  width: 368px;
}
</style>
