<template>
  <div class="echarts-main">
    <div class="content-box">
      <div class="chart-title-box">
        <span class="chart-title">总票面金额</span>
        <el-tooltip effect="dark" content="平均票面额=总票面金额/票据张数" placement="top-start">
          <i class="el-icon-warning-outline" />
        </el-tooltip>
      </div>
      <div ref="oneChartRef" class="echarts-content" />
      <div class="amount">
        平均票面额 {{ numberToCurrency(responseData.billAmount.avgBillAmount) }}
      </div>
    </div>
    <div class="content-box">
      <div class="chart-title-box">
        <span class="chart-title">总购买金额</span>
        <el-tooltip effect="dark" content="平均购买额=总购买金额/订单笔数" placement="top-start">
          <i class="el-icon-warning-outline" />
        </el-tooltip>
      </div>
      <div ref="twoChartRef" class="echarts-content" />
      <div class="amount">
        平均购买额 {{ numberToCurrency(responseData.buyBillAmount.avgBuyBillAmount) }}
      </div>
    </div>
    <div class="content-box">
      <div class="chart-title-box">
        <span class="chart-title">总发放收益</span>
        <el-tooltip effect="dark" content="平均年收益率=Σ订单预计年化收益率/订单笔数" placement="top-start">
          <i class="el-icon-warning-outline" />
        </el-tooltip>
      </div>
      <div ref="threeChartRef" class="echarts-content" />
      <div class="amount">
        平均年收益率 {{ responseData.provideIncomeAmount.avgIncomeBillAmountRate }}%
      </div>
    </div>
    <div class="content-box">
      <div class="chart-title-box">
        <span class="chart-title">订单笔数</span>
        <el-tooltip effect="dark" content="转换率=总购买金额/总票面金额" placement="top-start">
          <i class="el-icon-warning-outline" />
        </el-tooltip>
      </div>
      <div ref="fourChartRef" class="echarts-content" />
      <div class="amount">
        转换率 {{ responseData.orderQuantity.transferRate }}%
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, nextTick } from 'vue'
import { getBillAmount, getBillOrderQuantity, getBuyBillAmount, getProvideIncomeAmount } from '@/api/piaoju.js'
import { numberToCurrency } from '@/utils/util.js'
import * as echarts from 'echarts'

const oneChartRef = ref(null)
const twoChartRef = ref(null)
const threeChartRef = ref(null)
const fourChartRef = ref(null)
const responseData = reactive({
  billAmount: {},
  buyBillAmount: {},
  provideIncomeAmount: {},
  orderQuantity: {}
})
const echartsData = reactive({
  oneChartOption: {
    tooltip: {
      trigger: 'axis',
      position: ['98%', '10%'],
      formatter: function(params) {
        let str =
          `<span style="font-size: 12px">${params[0].axisValue}</span>` +
          '<br />'
        params.forEach((item) => {
          str +=
            `<span>${item.seriesName}</span>` +
            ' : ' +
            `<span style="margin-left: 10px;">￥${numberToCurrency(item.data)}</span>` +
            '<br />'
        })
        return str
      }
    },
    grid: {
      top: '40%',
      left: '0',
      right: '0',
      bottom: '0'
    },
    title: {
      text: '',
      textStyle: {
        fontSize: 22,
        color: '#333'
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false // 隐藏x轴
    },
    yAxis: {
      type: 'value',
      show: false // 隐藏y轴
    },
    series: [
      {
        name: '票面金额',
        data: [],
        type: 'line',
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: 'rgba(1, 114, 235, 1)'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(1, 114, 235, 0.4)'
            },
            {
              offset: 1,
              color: 'rgba(255,255,255, 0.1)'
            }
          ])
        }
      }
    ]
  },
  twoChartOption: {
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        let str =
          `<span style="font-size: 12px">${params[0].axisValue}</span>` +
          '<br />'
        params.forEach((item) => {
          str +=
            `<span>${item.seriesName}</span>` +
            ' : ' +
            `<span style="margin-left: 10px;">￥${numberToCurrency(item.data)}</span>` +
            '<br />'
        })
        return str
      }
    },
    grid: {
      top: '40%',
      left: '0',
      right: '0',
      bottom: '0'
    },
    title: {
      text: '',
      textStyle: {
        fontSize: 22,
        color: '#333'
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false // 隐藏x轴
    },
    yAxis: {
      type: 'value',
      show: false // 隐藏y轴
    },
    series: [
      {
        name: '购买金额',
        data: [],
        type: 'line',
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: 'rgba(243, 85, 34, 1)'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(243, 85, 34, 0.4)'
            },
            {
              offset: 1,
              color: 'rgba(255,255,255, 0.1)'
            }
          ])
        }
      }
    ]
  },
  threeChartOption: {
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        let str =
          `<span style="font-size: 12px">${params[0].axisValue}</span>` +
          '<br />'
        params.forEach((item) => {
          str +=
            `<span>${item.seriesName}</span>` +
            ' : ' +
            `<span style="margin-left: 10px;">￥${numberToCurrency(item.data)}</span>` +
            '<br />'
        })
        return str
      }
    },
    grid: {
      top: '40%',
      left: '0',
      right: '0',
      bottom: '0'
    },
    title: {
      text: '',
      textStyle: {
        fontSize: 22,
        color: '#333'
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false // 隐藏x轴
    },
    yAxis: {
      type: 'value',
      show: false // 隐藏y轴
    },
    series: [
      {
        name: '发放收益',
        data: [],
        type: 'line',
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: 'rgba(255, 161, 19, 1)'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 161, 19, 0.4)'
            },
            {
              offset: 1,
              color: 'rgba(255,255,255, 0.1)'
            }
          ])
        }
      }
    ]
  },
  fourChartOption: {
    color: ['#0172EB'],
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        let str =
          `<span style="font-size: 12px">${params[0].axisValue}</span>` +
          '<br />'
        params.forEach((item) => {
          str +=
            `<span>${item.seriesName}</span>` +
            ' : ' +
            `<span style="margin-left: 10px;">${item.data}笔</span>` +
            '<br />'
        })
        return str
      }
    },
    grid: {
      top: '40%',
      left: '0',
      right: '0',
      bottom: '0'
    },
    title: {
      text: '',
      textStyle: {
        fontSize: 22,
        color: '#333'
      }
    },
    xAxis: {
      type: 'category',
      data: [],
      show: false // 隐藏x轴
    },
    yAxis: {
      type: 'value',
      show: false // 隐藏y轴
    },
    series: [
      {
        name: '订单笔数',
        data: [],
        type: 'bar',
        barWidth: '6px',
        itemStyle: {
          borderRadius: [10, 10, 0, 0]
        }
      }
    ]
  }
})

const echartsOperate = reactive({
  rendChartsOne() {
    const myCharts = echarts.init(oneChartRef.value)
    myCharts.setOption(echartsData.oneChartOption, true)
    window.addEventListener('resize', function() {
      if (myCharts) myCharts.resize()
    })
  },
  rendChartsTwo() {
    const myCharts = echarts.init(twoChartRef.value)
    myCharts.setOption(echartsData.twoChartOption, true)
    window.addEventListener('resize', function() {
      if (myCharts) myCharts.resize()
    })
  },
  rendChartsThree() {
    const myCharts = echarts.init(threeChartRef.value)
    myCharts.setOption(echartsData.threeChartOption, true)
    window.addEventListener('resize', function() {
      if (myCharts) myCharts.resize()
    })
  },
  rendChartsFour() {
    const myCharts = echarts.init(fourChartRef.value)
    myCharts.setOption(echartsData.fourChartOption, true)
    window.addEventListener('resize', function() {
      if (myCharts) myCharts.resize()
    })
  }
})
function getData() {
  getBillAmount(res => {
    responseData.billAmount = res.data
    const XData = res.data.billAmountList.map(item => item.dayDate)
    const YData = res.data.billAmountList.map(item => item.dayBillAmount)
    echartsData.oneChartOption.xAxis.data = XData
    echartsData.oneChartOption.series[0].data = YData
    echartsData.oneChartOption.title.text = numberToCurrency(res.data.totalBillAmount)
    nextTick(() => {
      echartsOperate.rendChartsOne()
    })
  })
  getBuyBillAmount(res => {
    responseData.buyBillAmount = res.data
    const XData = res.data.buyBillAmountList.map(item => item.dayDate)
    const YData = res.data.buyBillAmountList.map(item => item.dayBuyBillAmount)
    echartsData.twoChartOption.xAxis.data = XData
    echartsData.twoChartOption.series[0].data = YData
    echartsData.twoChartOption.title.text = numberToCurrency(res.data.totalBuyBillAmount)
    nextTick(() => {
      echartsOperate.rendChartsTwo()
    })
  })
  getProvideIncomeAmount(res => {
    responseData.provideIncomeAmount = res.data
    const XData = res.data.incomeBillAmountList.map(item => item.dayDate)
    const YData = res.data.incomeBillAmountList.map(item => item.dayIncomeBillAmount)
    echartsData.threeChartOption.xAxis.data = XData
    echartsData.threeChartOption.series[0].data = YData
    echartsData.threeChartOption.title.text = numberToCurrency(res.data.totalIncomeBillAmount)
    nextTick(() => {
      echartsOperate.rendChartsThree()
    })
  })
  getBillOrderQuantity(res => {
    responseData.orderQuantity = res.data
    const XData = res.data.billOrderQuantityList.map(item => item.dayDate)
    const YData = res.data.billOrderQuantityList.map(item => item.dayBillOrderQuantity)
    echartsData.fourChartOption.xAxis.data = XData
    echartsData.fourChartOption.series[0].data = YData
    echartsData.fourChartOption.title.text = res.data.totalBillOrderQuantity
    nextTick(() => {
      echartsOperate.rendChartsFour()
    })
  })
}
onMounted(() => {
  getData()
})
</script>

<style lang="scss" scoped>
.echarts-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}
.content-box {
  width: calc(25% - 18px);
  height: 213px;
  box-shadow: -4px -4px 10px 0 #eaedf566, 4px 4px 10px 0 #eaedf566;
  background: #ffffffff;
  padding: 24px;
  font-size: 14px;
  box-sizing: border-box;

  .chart-title-box {
    color: $mainFontColor;
    margin-bottom: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-tooltip__trigger {
      cursor: pointer;
    }
  }

  .echarts-content {
    height: 90px;
    margin-bottom: 16px;
  }

  .amount {
    color: #666;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #DDDDDD;
  }
}
</style>
