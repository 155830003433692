<template>
  <div class="home-right-box">
    <div class="right-top" :style="{background: `url(${$localPicture}piaojuImg/home_right_top.png)`}">
      <p>票据服务系统</p>
      <p>全程线上操作，智能精准，高效便捷</p>
    </div>
    <div class="right-table-box">
      <h3 class="table-title">
        客户购买金额排名
      </h3>
      <Table
        :item-data="itemData"
        :list-data="clientBuyListData"
        :border="false"
        :stripe="false"
      >
        <template #sort="{index}">
          <img v-if="index < 3" :src="getTopImg(index + 1)" alt="" class="sort">
          <p v-else class="sort">
            {{ index + 1 }}
          </p>
        </template>
      </Table>
    </div>
    <div class="right-table-box">
      <h3 class="table-title">
        客户领取收益排名
      </h3>
      <Table
        :item-data="itemData"
        :list-data="clientIncomeListData"
        :border="false"
        :stripe="false"
      >
        <template #sort="{index}">
          <img v-if="index < 3" :src="getTopImg(index + 1)" alt="" class="sort">
          <p v-else class="sort">
            {{ index + 1 }}
          </p>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { getClientBuyAmountRank, getClientIncomeAmountRank } from '@/api/piaoju.js'
export default {
  components: { Table },
  data() {
    return {
      itemData: [
        { label: 'TOP ', prop: 'sort', width: '22px' },
        { label: '用户名称 ', prop: 'user', width: '70px' },
        { label: '金额 ', prop: 'amount', type: 'money' }
      ],
      clientBuyListData: [],
      clientIncomeListData: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getClientBuyAmountRank(res => {
        this.clientBuyListData = res.data
        this.clientBuyListData.forEach((item) => {
          item.user = item.userName.substring(0, 4) + '(尾号' + item.phoneNo.substr(-4) + ')'
          item.amount = item.buyAmount
        })
      })
      getClientIncomeAmountRank(res => {
        this.clientIncomeListData = res.data
        this.clientIncomeListData.forEach((item) => {
          item.user = item.userName.substring(0, 4) + '(尾号' + item.phoneNo.substr(-4) + ')'
          item.amount = item.incomeAmount
        })
      })
    },
    getTopImg(index) {
      if (index === 0) return
      return this.$localPicture + `piaojuImg/top${index}.png`
    }
  }
}
</script>

<style lang="scss" scoped>
.home-right-box {

  .right-top {
    width: 368px;
    height: 96px;
    background-size: 100% 100% !important;
    color: #fff;
    margin-bottom: 24px;
    p {
      padding-left: 24px;
    }
    p:nth-child(1) {
      font-size: 18px;
      padding-top: 24px;
      margin-bottom: 4px;
    }
    p:nth-child(2) {
      font-size: 14px;
    }
  }

  .right-table-box {
    width: 100%;
    height: 300px;
    box-shadow: -4px -4px 10px 0 #eaedf566, 4px 4px 10px 0 #eaedf566;
    margin-bottom: 24px;
    padding: 24px;
    box-sizing: border-box;

    .table-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    /deep/ .el-table .el-table__header th {
      padding: 2px 0;
      height: 40px;
      font-weight: 500;
      color: #333;
      background-color: #F9F9F9;
      font-size: 14px;
    }

    /deep/ .el-table td {
      padding: 2px 0;
      height: 40px;
      font-size: 14px;
    }
  }

  /deep/ .el-table {
    border-left: none;
    border-right: none;
    border-bottom: none;
    .cell {
      padding: 0 0 0 8px;
      text-align: left;
    }
  }

  .sort {
    width: 32px;
    height: 32px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
